@import url('https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

html, body {
  // -webkit-overflow-scrolling: touch
  overflow: auto;
  height: 100vh;
  position: relative; }

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  color: #4A4A4A; }

h1, h2, h3, h4, h5, h6, bold, strong {
  font-weight: 700; }

h1 {
  font-size: 2.625rem;
  font-weight: 800;
  margin-bottom: 2rem; }

h2 {
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 2rem; }

h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

