.container {
    display: grid;
    grid-row-gap: 1em;
    grid-column-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    @media (min-width: 32rem) {
        grid-template-columns: 1fr 1fr 1fr; }
    @media (min-width: 64rem) {
        grid-template-columns: 1fr 1fr 1fr; }
    @media (min-width: 70.5rem) {
        grid-template-columns: 1fr 1fr 1fr 1fr; } }
