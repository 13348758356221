.profileTop {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 2rem; }

.profileImage {
    border-radius: 100%;
    height: 6.25rem;
    width: 6.25rem;
    background-color: #4A4A4A;
    margin-right: 2rem; }


.username {
    font-weight:  700;
    font-size: 1.125rem;
    color: #4A4A4A; }

.button {
    background: #FD868C;
    border-radius: 0.3125rem;
    font-weight: 600;
    font-size: 1.125rem;
    color: #FFFFFF;
    height: 2rem;
    line-height: 2rem;
    padding: 0 1rem;
    border: none;
    margin-top: 0.5rem;
    cursor: pointer; }

.buttonFollowing {
    @extend .button;
    background: white;
    border: 0.125rem solid #FD868C;
    color: #FD868C; }

.profileStats {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin: 0 1.125rem; }

.number {
    font-family: Arial;
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 0.25rem; }

.label {
    font-weight: 500;
    font-size: 0.875rem; }
