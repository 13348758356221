.wrapper {
    margin: 8rem auto; }

.intro {
    margin-bottom: 2rem; }

.featured {
    font-size: 0.875rem;
    color: #4A4A4A;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0.5rem; }

.title {
    margin-top: 0.5rem;
    margin-bottom: 1rem; }

.text {
    margin-top: 1rem;
    margin-bottom: 0; }
