.activityContainer {
    width: 5rem;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin-right: 1.5rem;
    @media (min-width: 75rem) {
        width: 6.25rem; }
    &:first-child {
        padding-left: 5%;
        @media (min-width: 64rem) {
            padding-left: 0; } }
    &:last-child {
        padding-right: 5%;
        @media (min-width: 64rem) {
            padding-right: 0; } } }


.img {
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    background-color: #4A4A4A;
    background-size: cover;
    background-position: 50% 50%;
    margin-bottom: 1rem;
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(0,0,0,0.15);
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    @media (min-width: 75rem) {
        width: 6.25rem;
        height: 6.25rem; } }

.imgSelected {
    @extend .img;
    border: 0.1875rem solid #FD868C;
    transition: all 0.3s ease-in-out; }

.title {
    margin: 0 auto;
    font-weight: 500;
    transition: all 0.3s ease-in-out; }

.titleSelected {
    @extend .title;
    color: #F78CA0;
    font-weight: 700;
    transition: all 0.3s ease-in-out; }
