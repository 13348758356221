.search {
    transition: all 0.3s ease-in-out;
    width: 100%;
    display: flex;
    @media (min-width: 64rem) {
        width: auto; }
    input {
        select: focus;
        -webkit-appearance: none;
        -webkit-overflow-scrolling: touch;
        box-sizing: border-box;
        height: 3.125rem;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0 0.3125rem 0.625rem 0 rgba(0,0,0,0.15);
        border-radius: 0.3125rem;
        border: none;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 1.125rem;
        font-family: 'Raleway', sans-serif;
        font-weight: 600;
        color: #4A4A4A;
        @media (min-width: 64rem) {
            width: 20rem; } }
    input::placeholder {
        color: #4A4A4A;
        padding-left: 2rem;
        background-image: url(./searchIcon.svg);
        background-repeat: no-repeat;
        background-position: 0rem, 1rem; }
    input:focus {
        outline: none !important;
        border: 0.125rem solid #4A4A4A;
        height: 3.125rem; } }

.searchCompact {
    @extend .search;
    display: flex;
    @media screen and (max-width: 48rem - 0.0625) {
        border-radius: 50%;
        background: white;
        cursor: pointer;
        margin-right: 1rem;
        width: 2.8125rem;
        height: 2.8125rem;
        box-shadow: 0 0.3125rem 0.625rem 0 rgba(0,0,0,0.15);
        background-image: url(./searchIcon.svg);
        background-repeat: no-repeat;
        background-position: 50%, 50%;
        transition: border 0.3s ease-in-out;
        input {
            display: none; } } }

.searchActive {
    @extend .search;
    @media screen and (max-width: 48rem - 0.0625) {
        width: 100%;
        display: flex;
        align-items: center;
        margin-right: 0;
        input {
            width: 100%; } } }
.cancel {
    font-weight: 500;
    color: #4A4A4A;
    margin-left: 1.5625rem;
    text-align: right;
    cursor: pointer;
    @media screen and (min-width: 48rem) {
        display: none; } }

.cancelHidden {
    display: none; }

.suggestionsContainer {
    max-width: 72.5rem;
    margin: 0 auto;
    width: 100%;
    position: fixed;
    top: 4rem;
    z-index: -1; }

.suggestions {
    height: 100vh;
    background-color: white;
    z-index: -1;
    @media screen and (min-width: 48rem) {
        height: 20rem;
        background-color: white;
        border-radius: 0.3125rem;
        box-shadow: 0 0.3125rem 0.625rem 0 rgba(0,0,0,0.15);
        max-width: 40rem; } }

.suggestionsContent {
    text-align: left;
    font-weight: 500; }

.suggestionsTitle {
    margin: 0;
    padding: 1rem;
    padding-left: 0;
    padding-top: 2rem;
    @media screen and (min-width: 48rem) {
        padding-left: 1rem; } }

.suggestionsItems {
    margin: 0;
    overflow-y: scroll;
    height: calc(100vh - 6.875rem);
    @media screen and (min-width: 48rem) {
        height: 13.125rem; }

    @media screen and (min-width: 48rem) {
        padding-left: 1rem; } }
.hiddenSuggestions {
    display: none; }

.selectWrapper {
    padding-top: 1.5rem;
    @media screen and (min-width: 48rem) {
        margin-left: 1rem;
        margin-top: 1.5rem; } }


.radio {
    display: none; }

.selectButton {
    background: #FFFFFF;
    border: 0.125rem solid #4A4A4A;
    border-radius: 0.3125rem;
    padding: 0.25rem 0.5rem;
    &:first-child {
        margin-right: 1rem; } }

.checkedButton {
    @extend .selectButton;
    background: #4A4A4A;
    color: white; }

.itemLink {
    color: #4A4A4A;
    font-weight: 500; }

.item {
    padding-top: 1rem;
    padding-bottom: 1rem; }

