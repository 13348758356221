.button {
    font-family: 'Raleway', sans-serif;
    height: 3.125rem;
    border: none;
    border-radius: 0.3125rem;
    font-size: 1.125rem;
    font-weight: 700;
    padding-left: 2rem;
    padding-right: 2rem;
    color: white;
    background-image: linear-gradient(-46deg, #FE9A8B 0%, #FD868C 37%, #F9748F 77%, #F78CA0 100%);
    cursor: pointer;
    min-width: 8rem;
    transition: all .3s ease-in-out;
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(0,0,0,0.15);

    &:hover {
        background-image: linear-gradient(-46deg, #FDBBB2 0%, #F99BA0 37%, #FC8DA4 77%, #FAA1B2 100%);
        transition: all .3s ease-in-out;
        box-shadow: 0 0.625rem 0.625rem 0 rgba(0,0,0,0.15); } }

.buttonSecondary {
    @extend .button;
    background: #FFFFFF;
    border: 0.125rem solid #FD868C;
    color: #FD868C;
    text-align: center;
    &:hover {
        box-shadow: 0 0.625rem 0.625rem 0 rgba(0,0,0,0.15);
        color: #FD868C;
        background: #FFFFFF; } }
