@import url(https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
html, body {
  overflow: auto;
  height: 100vh;
  position: relative; }

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  color: #4A4A4A; }

h1, h2, h3, h4, h5, h6, bold, strong {
  font-weight: 700; }

h1 {
  font-size: 2.625rem;
  font-weight: 800;
  margin-bottom: 2rem; }

h2 {
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 2rem; }

h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.pageWrapper_pageWrapper__tfGIc {
  display: flex;
  flex-wrap: wrap;
  max-width: 72.5rem;
  margin: 0 auto;
  width: 90%; }

.pageWrapper_pageWrapperContent__33gTa {
  margin: 0 auto;
  margin-top: 4.375rem;
  padding-top: 3.5rem;
  padding-bottom: 5rem;
  width: 100%; }
  @media (min-width: 64rem) {
    .pageWrapper_pageWrapperContent__33gTa {
      padding-top: 7.5rem; } }

.header_nav__3NGn7 {
  position: fixed;
  top: 0;
  left: 0;
  height: 4.0625rem;
  z-index: 2;
  width: 100%;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  background-color: white; }

.header_navContent__20Cik {
  max-width: 72.5rem;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 5%; }

.header_navLeftPermanent__1urvf {
  display: flex;
  align-self: center;
  z-index: 2;
  min-width: 6rem;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.header_navLeft__3v74Y {
  display: none; }
  @media screen and (min-width: 48rem) {
    .header_navLeft__3v74Y {
      display: flex;
      align-self: center;
      z-index: 2;
      min-width: 6rem;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; } }

.header_navLeftHidden__382hZ {
  display: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  @media screen and (min-width: 48rem) {
    .header_navLeftHidden__382hZ {
      align-self: center;
      z-index: 2;
      min-width: 6rem;
      display: flex; } }

.header_navRight__D-6V2 {
  text-align: right;
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: flex-end; }

.header_menu__1ztzB, .header_show__f4RCX {
  display: none; }
  @media screen and (min-width: 48rem) {
    .header_menu__1ztzB, .header_show__f4RCX {
      display: flex;
      align-self: center;
      justify-content: flex-end;
      width: 100%; } }

@media screen and (max-width: 47.9375rem) {
  .header_show__f4RCX {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column;
    text-align: left;
    background: white;
    padding-top: 10rem;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around; } }

.header_navItem__3Q2Pe {
  text-decoration: none;
  align-self: center;
  margin-bottom: 4rem;
  font-size: 1.125rem; }
  @media screen and (min-width: 48rem) {
    .header_navItem__3Q2Pe {
      margin-left: 3rem;
      margin-bottom: 0; } }
  @media screen and (min-width: 64rem) {
    .header_navItem__3Q2Pe {
      margin-left: 4rem; } }
  .header_navItem__3Q2Pe:first-child {
    margin-left: 0; }

.header_burgerContainer__2x_S4, .header_burgerContainerOpen__1SeHY {
  display: flex;
  border-radius: 50%;
  background: white;
  margin-left: 2rem;
  cursor: pointer;
  width: 2.8125rem;
  min-width: 2.8125rem;
  height: 2.8125rem;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
  z-index: 1; }
  @media screen and (min-width: 48rem) {
    .header_burgerContainer__2x_S4, .header_burgerContainerOpen__1SeHY {
      display: none; } }

.header_burgerContainerHidden__2eK5c {
  display: none; }

.header_bar1__2WdBI, .header_bar2__1n2ts, .header_bar3__1G2kG {
  width: 1.3125rem;
  height: 0.125rem;
  background-color: #4A4A4A;
  margin: auto;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.header_allBars__1jfuh {
  width: 100%;
  align-self: center; }

.header_burgerContainerOpen__1SeHY .header_bar1__2WdBI {
  -webkit-transform: rotate(-45deg) translate(-5px, 5px);
  transform: rotate(-45deg) translate(-5px, 5px); }

.header_burgerContainerOpen__1SeHY .header_bar2__1n2ts {
  opacity: 0; }

.header_burgerContainerOpen__1SeHY .header_bar3__1G2kG {
  -webkit-transform: rotate(45deg) translate(-5px, -5px);
  transform: rotate(45deg) translate(-5px, -5px); }

.button_button__1AEw-, .button_buttonSecondary__30mKN {
  font-family: 'Raleway', sans-serif;
  height: 3.125rem;
  border: none;
  border-radius: 0.3125rem;
  font-size: 1.125rem;
  font-weight: 700;
  padding-left: 2rem;
  padding-right: 2rem;
  color: white;
  background-image: linear-gradient(-46deg, #FE9A8B 0%, #FD868C 37%, #F9748F 77%, #F78CA0 100%);
  cursor: pointer;
  min-width: 8rem;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.15); }
  .button_button__1AEw-:hover, .button_buttonSecondary__30mKN:hover {
    background-image: linear-gradient(-46deg, #FDBBB2 0%, #F99BA0 37%, #FC8DA4 77%, #FAA1B2 100%);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    box-shadow: 0 0.625rem 0.625rem 0 rgba(0, 0, 0, 0.15); }

.button_buttonSecondary__30mKN {
  background: #FFFFFF;
  border: 0.125rem solid #FD868C;
  color: #FD868C;
  text-align: center; }
  .button_buttonSecondary__30mKN:hover {
    box-shadow: 0 0.625rem 0.625rem 0 rgba(0, 0, 0, 0.15);
    color: #FD868C;
    background: #FFFFFF; }

.searchBar_search__13qbm, .searchBar_searchCompact__OCFYT, .searchBar_searchActive__1Jjyi {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
  display: flex; }
  @media (min-width: 64rem) {
    .searchBar_search__13qbm, .searchBar_searchCompact__OCFYT, .searchBar_searchActive__1Jjyi {
      width: auto; } }
  .searchBar_search__13qbm input, .searchBar_searchCompact__OCFYT input, .searchBar_searchActive__1Jjyi input {
    select: focus;
    -webkit-appearance: none;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    height: 3.125rem;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
    border-radius: 0.3125rem;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.125rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    color: #4A4A4A; }
    @media (min-width: 64rem) {
      .searchBar_search__13qbm input, .searchBar_searchCompact__OCFYT input, .searchBar_searchActive__1Jjyi input {
        width: 20rem; } }
  .searchBar_search__13qbm input::-webkit-input-placeholder, .searchBar_searchCompact__OCFYT input::-webkit-input-placeholder, .searchBar_searchActive__1Jjyi input::-webkit-input-placeholder {
    color: #4A4A4A;
    padding-left: 2rem;
    background-image: url(/static/media/searchIcon.da89f711.svg);
    background-repeat: no-repeat;
    background-position: 0rem, 1rem; }
  .searchBar_search__13qbm input::-moz-placeholder, .searchBar_searchCompact__OCFYT input::-moz-placeholder, .searchBar_searchActive__1Jjyi input::-moz-placeholder {
    color: #4A4A4A;
    padding-left: 2rem;
    background-image: url(/static/media/searchIcon.da89f711.svg);
    background-repeat: no-repeat;
    background-position: 0rem, 1rem; }
  .searchBar_search__13qbm input:-ms-input-placeholder, .searchBar_searchCompact__OCFYT input:-ms-input-placeholder, .searchBar_searchActive__1Jjyi input:-ms-input-placeholder {
    color: #4A4A4A;
    padding-left: 2rem;
    background-image: url(/static/media/searchIcon.da89f711.svg);
    background-repeat: no-repeat;
    background-position: 0rem, 1rem; }
  .searchBar_search__13qbm input::-ms-input-placeholder, .searchBar_searchCompact__OCFYT input::-ms-input-placeholder, .searchBar_searchActive__1Jjyi input::-ms-input-placeholder {
    color: #4A4A4A;
    padding-left: 2rem;
    background-image: url(/static/media/searchIcon.da89f711.svg);
    background-repeat: no-repeat;
    background-position: 0rem, 1rem; }
  .searchBar_search__13qbm input::placeholder, .searchBar_searchCompact__OCFYT input::placeholder, .searchBar_searchActive__1Jjyi input::placeholder {
    color: #4A4A4A;
    padding-left: 2rem;
    background-image: url(/static/media/searchIcon.da89f711.svg);
    background-repeat: no-repeat;
    background-position: 0rem, 1rem; }
  .searchBar_search__13qbm input:focus, .searchBar_searchCompact__OCFYT input:focus, .searchBar_searchActive__1Jjyi input:focus {
    outline: none !important;
    border: 0.125rem solid #4A4A4A;
    height: 3.125rem; }

.searchBar_searchCompact__OCFYT {
  display: flex; }
  @media screen and (max-width: 47.9375rem) {
    .searchBar_searchCompact__OCFYT {
      border-radius: 50%;
      background: white;
      cursor: pointer;
      margin-right: 1rem;
      width: 2.8125rem;
      height: 2.8125rem;
      box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
      background-image: url(/static/media/searchIcon.da89f711.svg);
      background-repeat: no-repeat;
      background-position: 50%, 50%;
      -webkit-transition: border 0.3s ease-in-out;
      transition: border 0.3s ease-in-out; }
      .searchBar_searchCompact__OCFYT input {
        display: none; } }

@media screen and (max-width: 47.9375rem) {
  .searchBar_searchActive__1Jjyi {
    width: 100%;
    display: flex;
    align-items: center;
    margin-right: 0; }
    .searchBar_searchActive__1Jjyi input {
      width: 100%; } }

.searchBar_cancel__1h310 {
  font-weight: 500;
  color: #4A4A4A;
  margin-left: 1.5625rem;
  text-align: right;
  cursor: pointer; }
  @media screen and (min-width: 48rem) {
    .searchBar_cancel__1h310 {
      display: none; } }

.searchBar_cancelHidden__L7utN {
  display: none; }

.searchBar_suggestionsContainer__3OUbW {
  max-width: 72.5rem;
  margin: 0 auto;
  width: 100%;
  position: fixed;
  top: 4rem;
  z-index: -1; }

.searchBar_suggestions__1Q306 {
  height: 100vh;
  background-color: white;
  z-index: -1; }
  @media screen and (min-width: 48rem) {
    .searchBar_suggestions__1Q306 {
      height: 20rem;
      background-color: white;
      border-radius: 0.3125rem;
      box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
      max-width: 40rem; } }

.searchBar_suggestionsContent__1YpeO {
  text-align: left;
  font-weight: 500; }

.searchBar_suggestionsTitle__2lxpd {
  margin: 0;
  padding: 1rem;
  padding-left: 0;
  padding-top: 2rem; }
  @media screen and (min-width: 48rem) {
    .searchBar_suggestionsTitle__2lxpd {
      padding-left: 1rem; } }

.searchBar_suggestionsItems__2_7dN {
  margin: 0;
  overflow-y: scroll;
  height: calc(100vh - 6.875rem); }
  @media screen and (min-width: 48rem) {
    .searchBar_suggestionsItems__2_7dN {
      height: 13.125rem; } }
  @media screen and (min-width: 48rem) {
    .searchBar_suggestionsItems__2_7dN {
      padding-left: 1rem; } }

.searchBar_hiddenSuggestions__1vlFz {
  display: none; }

.searchBar_selectWrapper__c9Ne1 {
  padding-top: 1.5rem; }
  @media screen and (min-width: 48rem) {
    .searchBar_selectWrapper__c9Ne1 {
      margin-left: 1rem;
      margin-top: 1.5rem; } }

.searchBar_radio__1RURi {
  display: none; }

.searchBar_selectButton__3DIwG, .searchBar_checkedButton__t2HdZ {
  background: #FFFFFF;
  border: 0.125rem solid #4A4A4A;
  border-radius: 0.3125rem;
  padding: 0.25rem 0.5rem; }
  .searchBar_selectButton__3DIwG:first-child, .searchBar_checkedButton__t2HdZ:first-child {
    margin-right: 1rem; }

.searchBar_checkedButton__t2HdZ {
  background: #4A4A4A;
  color: white; }

.searchBar_itemLink__2SDWg {
  color: #4A4A4A;
  font-weight: 500; }

.searchBar_item__1BjWM {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.category_activityContainer__2TQPo {
  width: 5rem;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-right: 1.5rem; }
  @media (min-width: 75rem) {
    .category_activityContainer__2TQPo {
      width: 6.25rem; } }
  .category_activityContainer__2TQPo:first-child {
    padding-left: 5%; }
    @media (min-width: 64rem) {
      .category_activityContainer__2TQPo:first-child {
        padding-left: 0; } }
  .category_activityContainer__2TQPo:last-child {
    padding-right: 5%; }
    @media (min-width: 64rem) {
      .category_activityContainer__2TQPo:last-child {
        padding-right: 0; } }

.category_img__2gS47, .category_imgSelected__lQAmz {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  background-color: #4A4A4A;
  background-size: cover;
  background-position: 50% 50%;
  margin-bottom: 1rem;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  @media (min-width: 75rem) {
    .category_img__2gS47, .category_imgSelected__lQAmz {
      width: 6.25rem;
      height: 6.25rem; } }

.category_imgSelected__lQAmz {
  border: 0.1875rem solid #FD868C;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.category_title__2DTyW, .category_titleSelected__1xkha {
  margin: 0 auto;
  font-weight: 500;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.category_titleSelected__1xkha {
  color: #F78CA0;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.categories_categoriesWrapper__2bHOF {
  display: flex;
  overflow-x: scroll;
  width: 110%;
  margin-left: -5%;
  margin-bottom: 4rem;
  justify-content: flex-start;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none; }
  .categories_categoriesWrapper__2bHOF::-webkit-scrollbar {
    width: 0;
    display: none; }
  @media (min-width: 64rem) {
    .categories_categoriesWrapper__2bHOF {
      width: 100%;
      margin-left: 0; } }

.activity_activity__2Ks4v {
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.activity_image__3RzYk, .activity_imageLoading__3YKGO {
  width: 100%;
  max-width: 100%;
  height: 10rem;
  border-radius: 0.3125rem;
  background: #4A4A4A;
  background-size: cover;
  text-align: left; }
  @media (min-width: 48rem) {
    .activity_image__3RzYk, .activity_imageLoading__3YKGO {
      height: 15rem; } }

.activity_imageLoading__3YKGO {
  background-image: -webkit-gradient(linear, right top, left top, from(#EEEEEE), to(#D8D8D8));
  background-image: linear-gradient(270deg, #EEEEEE 0%, #D8D8D8 100%); }

.activity_loadingTextImage__1_uaY {
  max-width: 100%; }

.activity_informationContainer__2VfEB {
  text-align: left;
  padding: 1rem; }

.activity_location__5v_55 {
  font-size: 0.875rem;
  font-weight: 700;
  color: #BFBFBF;
  margin-bottom: 0.5rem; }

.activity_title__1fD00 {
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  color: #4A4A4A; }

.activity_rating__RxWgA {
  font-family: Arial;
  font-weight: 600;
  color: #FD868C; }

.activity_star__Y2aBO {
  margin-right: 0.25rem; }

.activities_container__1oEda {
  display: grid;
  grid-row-gap: 1em;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr 1fr; }
  @media (min-width: 32rem) {
    .activities_container__1oEda {
      grid-template-columns: 1fr 1fr 1fr; } }
  @media (min-width: 64rem) {
    .activities_container__1oEda {
      grid-template-columns: 1fr 1fr 1fr; } }
  @media (min-width: 70.5rem) {
    .activities_container__1oEda {
      grid-template-columns: 1fr 1fr 1fr 1fr; } }

.showMore_wrapper__3Hwi8 {
  display: flex;
  margin: 2rem auto;
  justify-content: space-around; }

.featured_wrapper__3t-8d {
  margin: 8rem auto; }

.featured_intro__3kOGS {
  margin-bottom: 2rem; }

.featured_featured__1qWSW {
  font-size: 0.875rem;
  color: #4A4A4A;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0.5rem; }

.featured_title__vKNNV {
  margin-top: 0.5rem;
  margin-bottom: 1rem; }

.featured_text__1GCYX {
  margin-top: 1rem;
  margin-bottom: 0; }

.signUpForm_content__3PqHY {
  display: flex;
  justify-content: space-between;
  align-items: top; }

.signUpForm_imageContainer__3UIo6 {
  text-align: right;
  width: 40%;
  display: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 100%; }
  @media (min-width: 32rem) {
    .signUpForm_imageContainer__3UIo6 {
      display: block;
      background-image: url(/static/media/signUpImageSmall.35528b32.png);
      width: 50%; } }
  @media (min-width: 48rem) {
    .signUpForm_imageContainer__3UIo6 {
      background-image: url(/static/media/signUpImage.407c0828.png);
      width: 32rem; } }

.signUpForm_image__3oJrU {
  max-width: 100%; }

.signUpForm_form__Nr-oh {
  display: block;
  margin-bottom: 2rem; }

.signUpForm_text__2ZboW {
  margin-bottom: 2rem; }

.signUpForm_input__3w-hx {
  display: block;
  height: 3.125rem;
  min-width: 10.625rem;
  width: 90%;
  max-width: 20.625rem;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  select: focus;
  -webkit-appearance: none;
  -webkit-overflow-scrolling: touch;
  background: #FFFFFF;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem; }

input::-webkit-input-placeholder {
  color: #4A4A4A; }

input::-moz-placeholder {
  color: #4A4A4A; }

input:-ms-input-placeholder {
  color: #4A4A4A; }

input::-ms-input-placeholder {
  color: #4A4A4A; }

input::placeholder {
  color: #4A4A4A; }

input:focus {
  outline: none !important;
  border: 0.125rem solid #4A4A4A;
  height: 2.875rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem; }

.loginForm_content__3JXtf {
  display: flex;
  justify-content: space-between;
  align-items: top; }

.loginForm_imageContainer__1_GAr {
  text-align: right;
  width: 40%;
  display: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 100%; }
  @media (min-width: 32rem) {
    .loginForm_imageContainer__1_GAr {
      display: block;
      background-image: url(/static/media/loginImageSmall.35528b32.png);
      width: 50%; } }
  @media (min-width: 48rem) {
    .loginForm_imageContainer__1_GAr {
      background-image: url(/static/media/loginImage.407c0828.png);
      width: 32rem; } }

.loginForm_image__2sv4O {
  max-width: 100%; }

.loginForm_form__jwkxD {
  display: block;
  margin-bottom: 2rem; }
  .loginForm_form__jwkxD button {
    min-width: 10rem; }

.loginForm_text__Z6caa {
  margin-bottom: 2rem; }

.loginForm_input___G4O1 {
  display: block;
  height: 3.125rem;
  min-width: 10.625rem;
  width: 90%;
  max-width: 20.625rem;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  select: focus;
  -webkit-appearance: none;
  -webkit-overflow-scrolling: touch;
  background: #FFFFFF;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem; }

input::-webkit-input-placeholder {
  color: #4A4A4A; }

input::-moz-placeholder {
  color: #4A4A4A; }

input:-ms-input-placeholder {
  color: #4A4A4A; }

input::-ms-input-placeholder {
  color: #4A4A4A; }

input::placeholder {
  color: #4A4A4A; }

input:focus {
  outline: none !important;
  border: 0.125rem solid #4A4A4A;
  height: 2.875rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem; }

.profileDetails_profileTop__2SL0I {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 2rem; }

.profileDetails_profileImage__jxnlP {
  border-radius: 100%;
  height: 6.25rem;
  width: 6.25rem;
  background-color: #4A4A4A;
  margin-right: 2rem; }

.profileDetails_username__1CNtN {
  font-weight: 700;
  font-size: 1.125rem;
  color: #4A4A4A; }

.profileDetails_button__1bfJV, .profileDetails_buttonFollowing__O6pry {
  background: #FD868C;
  border-radius: 0.3125rem;
  font-weight: 600;
  font-size: 1.125rem;
  color: #FFFFFF;
  height: 2rem;
  line-height: 2rem;
  padding: 0 1rem;
  border: none;
  margin-top: 0.5rem;
  cursor: pointer; }

.profileDetails_buttonFollowing__O6pry {
  background: white;
  border: 0.125rem solid #FD868C;
  color: #FD868C; }

.profileDetails_profileStats__23oKE {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: 0 1.125rem; }

.profileDetails_number__3gyD5 {
  font-family: Arial;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0.25rem; }

.profileDetails_label__VVlPj {
  font-weight: 500;
  font-size: 0.875rem; }

.list_wrapper__1l3a0 {
  margin: 4rem auto; }

.activityDetails_activityDetails__3cf6S {
  z-index: 3;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100vw;
  background: white;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll; }

.activityDetails_image__6JbUB {
  background: #4A4A4A;
  background-size: cover;
  height: 26.25rem; }

.activityDetails_header__2XTFJ {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 2rem;
  display: flex;
  left: 5%;
  right: 5%; }

.activityDetails_back__3fwUq {
  color: white;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center; }

.activityDetails_like__1rKxS, .activityDetails_liked__LWX9g {
  cursor: pointer;
  background: #FFFFFF;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  background: white;
  background-image: url(/static/media/like.559454d1.svg);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 50%, 50%;
  height: 3.125rem;
  width: 3.125rem; }

.activityDetails_liked__LWX9g {
  background-image: url(/static/media/liked.99455068.svg); }

.activityDetails_backSymbol__3hNfO {
  margin-right: 0.5rem; }

.activityDetails_informationContainer__3pB8I {
  background-color: white;
  padding-top: 2rem;
  padding-bottom: 5rem;
  width: 90%;
  margin: 0 auto; }

.activityDetails_title__LyGSY {
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #4A4A4A;
  font-size: 2rem; }

.activityDetails_rating__22O1_ {
  font-family: Arial;
  font-weight: 600;
  color: #FD868C;
  margin-bottom: 4rem; }

.activityDetails_star__3fFb4 {
  margin-right: 0.25rem; }

.App {
  text-align: center; }

a, .link {
  text-decoration: none;
  font-weight: 700;
  color: #FD868C;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  align-self: center; }
  a:hover, a:focus, .link:hover, .link:focus {
    color: #8AB6FF; }

