.activityDetails {
    z-index: 3;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    background: white;
    min-height: 100vh;
    height: 100%;
    overflow-y: scroll; }

.image {
    background: #4A4A4A;
    background-size: cover;
    height: 26.25rem; }

.header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 2rem;
    display: flex;
    left: 5%;
    right: 5%; }

.back {
    color: white;
    font-weight: 700;
    cursor: pointer;
    display: flex;
    align-items: center; }

.like {
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.15);
    border-radius: 100%;
    background: white;
    background-image: url("like.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 50%, 50%;
    height: 3.125rem;
    width: 3.125rem; }

.liked {
    @extend .like;
    background-image: url("liked.svg"); }

.backSymbol {
    margin-right: 0.5rem; }

.informationContainer {
    background-color: white;
    padding-top: 2rem;
    padding-bottom: 5rem;
    width: 90%;
    margin: 0 auto; }

.title {
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #4A4A4A;
    font-size: 2rem; }

.rating {
    font-family: Arial;
    font-weight: 600;
    color: #FD868C;
    margin-bottom: 4rem; }

.star {
    margin-right: 0.25rem; }
