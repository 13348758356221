.activity {
    cursor: pointer;
    transition: all 0.3s; }

.image {
    width: 100%;
    max-width: 100%;
    height: 10rem;
    border-radius: 0.3125rem;
    background: #4A4A4A;
    background-size: cover;
    text-align: left;
    @media (min-width: 48rem) {
        height: 15rem; } }

.imageLoading {
    @extend .image;
    background-image: linear-gradient(270deg, #EEEEEE 0%, #D8D8D8 100%); }

.loadingTextImage {
    max-width: 100%; }

.informationContainer {
    text-align: left;
    padding: 1rem; }

.location {
    font-size: 0.875rem;
    font-weight: 700;
    color: #BFBFBF;
    margin-bottom: 0.5rem; }

.title {
    font-weight: 700;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
    color: #4A4A4A; }

.rating {
    font-family: Arial;
    font-weight: 600;
    color: #FD868C; }

.star {
    margin-right: 0.25rem; }
