.categoriesWrapper {
  display: flex;
  overflow-x: scroll;
  width: 110%;
  margin-left: -5%;
  margin-bottom: 4rem;
  justify-content: flex-start;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    display: none; }
  @media (min-width: 64rem) {
    width: 100%;
    margin-left: 0; }

  // &:before
  //   content: ""
  //   position: absolute
  //   z-index: 1
  //   top: 21rem
  //   left: 0
  //   height: 5.1875rem
  //   pointer-events: none
  //   background-image: linear-gradient(to left, rgba(255,255,255,0), white 85%)
  //   width: 7%
  //   @media (min-width: 64rem)
  //     display: none


  // &:after
  //   content: ""
  //   position: absolute
  //   z-index: 1
  //   top: 21rem
  //   right: 0
  //   height: 5.1875rem
  //   pointer-events: none
  //   background-image: linear-gradient(to right, rgba(255,255,255,0), white 85%)
  //   width: 20%
  //   @media (min-width: 64rem)
 }  //     display: none



