.nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 4.0625rem;
    z-index: 2;
    width: 100%;
    transition: all .2s ease-in-out;
    background-color: white; }

.navContent {
    max-width: 72.5rem;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 5%; }

.navLeftPermanent {
    display: flex;
    align-self: center;
    z-index: 2;
    min-width: 6rem;
    transition: all 0.3s ease-in-out; }

.navLeft {
    display: none;
    @media screen and (min-width: 48rem) {
        display: flex;
        align-self: center;
        z-index: 2;
        min-width: 6rem;
        transition: all 0.3s ease-in-out; } }


.navLeftHidden {
    display: none;
    transition: all 0.3s ease-in-out;
    @media screen and (min-width: 48rem) {
        align-self: center;
        z-index: 2;
        min-width: 6rem;
        display: flex; } }




.navRight {
    text-align: right;
    width: 100%;
    align-self: center;
    display: flex;
    justify-content: flex-end; }

.menu {
    display: none;
    @media screen and (min-width: 48rem) {
        display: flex;
        align-self: center;
        justify-content: flex-end;
        width: 100%; } }
.show {
    @extend .menu;
    @media screen and (max-width: 48rem - 0.0625) {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-flow: column;
        text-align: left;
        background: white;
        padding-top: 10rem;
        display: flex;
        flex-wrap: wrap;
        align-content: space-around; } }

.navItem {
    text-decoration: none;
    align-self: center;
    margin-bottom: 4rem;
    font-size: 1.125rem;
    @media screen and (min-width: 48rem) {
        margin-left: 3rem;
        margin-bottom: 0; }
    @media screen and (min-width: 64rem) {
        margin-left: 4rem; }
    &:first-child {
        margin-left: 0; } }

.burgerContainer {
    display: flex;
    border-radius: 50%;
    background: white;
    margin-left: 2rem;
    cursor: pointer;
    width: 2.8125rem;
    min-width: 2.8125rem;
    height: 2.8125rem;
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(0,0,0,0.15);
    z-index: 1;
    @media screen and (min-width: 48rem) {
        display: none; } }

.burgerContainerHidden {
    display: none; }

.bar1, .bar2, .bar3 {
    width: 1.3125rem;
    height: 0.125rem;
    background-color: #4A4A4A;
    margin: auto;
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
    transition: 0.4s; }
.allBars {
    width: 100%;
    align-self: center; }

.burgerContainerOpen {
    @extend .burgerContainer;
    .bar1 {
        -webkit-transform: rotate(-45deg) translate(-5px, 5px);
        transform: rotate(-45deg) translate(-5px, 5px); }
    .bar2 {
        opacity: 0; }
    .bar3 {
        -webkit-transform: rotate(45deg) translate(-5px, -5px);
        transform: rotate(45deg) translate(-5px, -5px); } }
