.content {
    display: flex;
    justify-content: space-between;
    align-items: top; }

.imageContainer {
    text-align: right;
    width: 40%;
    display: none;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 100%;
    @media (min-width: 32rem) {
        display: block;
        background-image: url(./signUpImageSmall.png);
        width: 50%; }
    @media (min-width: 48rem) {
        background-image: url(./signUpImage.png);
        width: 32rem; } }

.image {
    max-width: 100%; }

.form {
    display: block;
    margin-bottom: 2rem; }

.text {
    margin-bottom: 2rem; }

.input {
    display: block;
    height: 3.125rem;
    min-width: 10.625rem;
    width: 90%;
    max-width: 20.625rem;
    font-size: 1rem;
    margin-bottom: 1.5rem;
    select: focus;
    -webkit-appearance: none;
    -webkit-overflow-scrolling: touch;
    background: #FFFFFF;
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(0,0,0,0.15);
    border-radius: 0.3125rem;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem; }
input::placeholder {
    color: #4A4A4A; }
input:focus {
    outline: none !important;
    border: 0.125rem solid #4A4A4A;
    height: 2.875rem;
    padding-left: 0.875rem;
    padding-right: 0.875rem; }
