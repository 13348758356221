.App {
  text-align: center; }

a, .link {
  text-decoration: none;
  font-weight: 700;
  color: #FD868C;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  align-self: center;

  &:hover, &:focus {
    color: #8AB6FF; } }
